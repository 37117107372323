import { render, staticRenderFns } from "./NotificationsButton.vue?vue&type=template&id=03f8dbdb&scoped=true&"
import script from "./NotificationsButton.vue?vue&type=script&lang=js&"
export * from "./NotificationsButton.vue?vue&type=script&lang=js&"
import style0 from "./NotificationsButton.vue?vue&type=style&index=0&id=03f8dbdb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f8dbdb",
  null
  
)

export default component.exports