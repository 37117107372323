<template>
  <div class="ch-upload-btns">
    <cs-button
      :disabled="isDisabled || !canImages"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="attach('image/*')"
    >
      <i class="ch-icons-add-media mr-2"></i>
      Photo
    </cs-button>
    <cs-button
      :disabled="isDisabled || !canVideo"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="attach('video/*')"
    >
      <i class="cs-icons-video-file-filled"></i>
      Video
    </cs-button>
    <cs-button
      :disabled="isDisabled || !canImages"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="openGIFsModal"
    >
      <i class="ch-icons-gif cs-upload-icons"></i>
      GIF
    </cs-button>
    <cs-button
      :disabled="isDisabled || !canDocument"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="attach('application/pdf')"
    >
      <i class="cs-icons-doc cs-upload-icons"></i>
      PDF
    </cs-button>
    <app-pay-announce-modal
      :showModal="isShowPayModal"
      @close-modal="isShowPayModal = false"
      @success-payment="onSuccessPayment"
      @on-success-transaction="$emit('on-success-transaction', $event)" 
    />
    <!-- <app-video-upload-charge-modal
      :showModal="isShowVideoChargeModal"
      @close-modal="isShowVideoChargeModal = false"
      @success-payment="onSuccessPayment"
    /> -->
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $utils from '@/services/utils';
import AppPayAnnounceModal from '@/components/payment/PayAnnounceModal.vue';
import IsVIPUser from '@/gql/general/IsVIPUser.gql';
// import AppVideoUploadChargeModal from '@/components/payment/VideoUploadChargeModal.vue';

export default {
  components: {
    AppPayAnnounceModal,
    // AppVideoUploadChargeModal
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isShowPayModal: false,
      isShowVideoChargeModal: false,
      videoInput: null,
      isVIP: false
    };
  },
  async mounted() {
    await this.isVIPUser();
  },
  computed: {
    hasImages() {
      return this.value.images && this.value.images.length > 0;
    },
    hasVideo() {
      return !!this.value.video;
    },
    hasDocument() {
      return !!this.value.document;
    },
    canImages() {
      return !this.hasVideo && !this.hasDocument;
    },
    canVideo() {
      return !this.hasImages && !this.hasDocument;
    },
    canDocument() {
      return !this.hasImages && !this.hasVideo;
    },
    isDisabled() {
      return this.loading || this.disabled;
    },
  },
  methods: {
    async isVIPUser() {
      const res = await this.$apollo.query({
        query: IsVIPUser
      });
      this.isVIP = res.data.isVIPUser;
    },
    async onSuccessPayment(success) {
      if(success) {
        this.$emit('input', this.videoInput);
      }
      this.isShowPayModal = false;
      this.videoInput = null;
    },
    // Attachments (Files and Images and Videos)
    async attach(type) {
      const files = await $utils.selectFiles(type);
      console.log(files)
      // this.$emit('loading', true);
      this.loading = true;

      const datafiles = Array.from(files);
      datafiles.forEach((file) => {
        file.dataurl = URL.createObjectURL(file);
      });

      if (type.startsWith('image')) {
        const images = this.value.images || [];
        this.$emit('input', {
          ...this.value,
          images: [...images, ...datafiles],
        });
      } else if (type.startsWith('video')) {
        const file = datafiles[0];
        // const {paid, price} = await $auth.getUserPaidStatus();
        await this.generateThumbnail(file);
      //  if (
      //     this.isVIP || (this.checkDurationsVideo(file.duration) &&
      //     this.checkSizeVideo(file.size)) || paid
      //   ) {
          this.$emit('input', { ...this.value, video: file });
        // } else {
        //   this.videoInput = { ...this.value, video: file };
        //   this.isShowPayModal = true;
        // }
      } else {
        const file = datafiles[0];
        this.$emit('input', { ...this.value, document: file });
      }
      // this.$emit('loading', false);
      this.loading = false;
    },
    generateThumbnailImage(video, canvas, time) {
      return new Promise((resolve) => {
        video.addEventListener('timeupdate', () => {
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          resolve(canvas.toDataURL());
        });
        video.currentTime = time;
      });
    },
    async generateThumbnail(file) {
      const videoUrl = file.dataurl;
      const video = document.createElement('video');
      video.src = videoUrl;
      return new Promise((resolve) => {
        video.addEventListener('loadedmetadata', () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const duration = video.duration;
          file.duration = duration;

          this.generateThumbnailImage(
            video,
            canvas,
            Math.floor(duration / 5)
          ).then((thumburl) => {
            file.thumburl = thumburl;
            resolve();
          });
        });
      });
    },

    // GIFS
    openGIFsModal() {
      $bus.$emit('show-select-gif-modal');
      $bus.$once('gif-modal-select', this.selectedGIF);
    },
    selectedGIF(gif) {
      const gifImage = {
        url: gif.url,
        name: 'Embedded Gif',
        type: 'image',
        contentType: 'image/gif',
      };
      const images = this.value.images || [];
      this.$emit('input', { ...this.value, images: [...images, gifImage] });
    },
    checkDurationsVideo(durations) {
      /*const mDuration = durations / 60;
      if (mDuration > 60) {
        return false;
      }*/
      return true;
    },
    checkSizeVideo(bytes) {
      /*const fileSizeInGB = bytes / 1024 ** 3;
      if (fileSizeInGB > 2) {
        return false;
      }*/
      return true;
    },
  },
};
</script>

<style scoped>
.ch-upload-btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  --cs-button-color: var(--cs-gray-01) !important;
  --cs-button-text-color: var(--cs-gray-05) !important;
}
.cs-upload-icons {
  margin-right: 5px;
}
</style>
