<template>
  <div>
    <h4 class="text-center">Active your account</h4>
    <payment-plans
      v-if="!isCreditForm"
      :isContentProvider="isContentProvider"
      @onSelectPlans="handleSelectPlans"
      @onShowCreditForm="handleShowCreditForm"
    />
    <credit-form v-if="isCreditForm" 
      @onBackToPlans="isCreditForm = false" 
      @on-success="finishSubmit"  
      :isContentProvider="isContentProvider"
    />
  </div>
</template>
<script>
import CreditForm from '@/components/payment/CreditForm.vue';
import PaymentPlans from '@/components/payment/PaymentPlans.vue';
import CreatePayment from '@/gql/payment/CreatePayment.gql';
import $auth from '@/services/auth';
import $popups from '@/services/popups';

export default {
  components: { CreditForm, PaymentPlans },
  data() {
    return {
      isContentProvider: true,
      isCreditForm: false,
    };
  },
  mounted() {
    // Dynamically create script element
    // const script = document.createElement('script');
    // script.src = 'https://secure.networkmerchants.com/token/Collect.js';
    // script.setAttribute('data-tokenization-key', '8J84Wg-DMqwPm-32qQEb-3BMrxU');
    // script.setAttribute('data-primary-color', '#0298d7');

    // script.onload = () => {
    //   // Configure Collect.js after script is loaded
    //   window.CollectJS.configure({
    //     callback: (token) => {
    //       this.finishSubmit(token);
    //     },
    //   });
    // };

    // // Append script element to the document body
    // document.body.appendChild(script);
  },
  methods: {
    handleSelectPlans(isContentProvider) {
      this.isContentProvider = isContentProvider;
    },
    handleShowCreditForm() {
      this.isCreditForm = true;
      // window.CollectJS.startPaymentRequest();
    },
    async finishSubmit(token) {
      const amount = this.isContentProvider ? 1.00 : 2.99;
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
      const currentYear = currentDate.getFullYear();

      $popups.alert({
          title: 'Payment Successful',
          message:
            'Your payment was successful. Thank you for your payment',
        });
      
      this.$emit('success-payment', true);
      console.log({
        userId: $auth.getUserId(),
        price: amount,
        month: currentMonth.toString(),
        year: currentYear.toString(),
        transactionStatus: token,
      });
      if(this.isContentProvider) {
        this.$emit('on-success-transaction',{
          userId: $auth.getUserId(),
          price: amount,
          month: currentMonth.toString(),
          year: currentYear.toString(),
          transactionStatus: JSON.stringify(token),
        });
      } else {
        await this.$apollo.mutate({
          mutation: CreatePayment,
          variables: {
            userId: $auth.getUserId(),
            price: amount,
            month: currentMonth.toString(),
            year: currentYear.toString(),
            transactionStatus: JSON.stringify(token),
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.plans-content {
  display: flex;
  justify-content: space-around;
  gap: 0 10px;
}
.plans-title {
  font-weight: bold;
  font-size: 14px;
}
.active-plans {
  border: 1px solid var(--cs-primary-base) !important;
}
.plans-card {
  position: relative;
  width: 50%;
  padding: 12px;
  border: solid 1px var(--cs-gray-02);
  border-radius: 15px;
  cursor: pointer;
}
.billing-btn {
  width: 100%;
  margin-top: 30px;
  border-radius: 3px !important;
}
.plans-check {
  position: absolute;
  right: 10px;
  top: 8px;
}
.uncheck-box {
  width: 16px;
  height: 16px;
  margin-top: 5px;
  border: solid 1px var(--cs-gray-02);
  border-radius: 50%;
}
.feature-item {
  display: flex;
  align-items: center;
  gap: 0 10px;
}
</style>
