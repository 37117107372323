import { render, staticRenderFns } from "./TranslationModal.vue?vue&type=template&id=59a5ff05&scoped=true&lang=html&"
import script from "./TranslationModal.vue?vue&type=script&lang=js&"
export * from "./TranslationModal.vue?vue&type=script&lang=js&"
import style0 from "./TranslationModal.vue?vue&type=style&index=0&id=59a5ff05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a5ff05",
  null
  
)

export default component.exports