var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-role-select"},[_c('label',{staticClass:"group-role-select__label"},[_vm._v("Recipients")]),_c('div',{staticClass:"group-role-select__wrapper"},[_c('div',{staticClass:"group-role-select__header"},[_c('div',{staticClass:"group-role-select__selected"},[(!_vm.recipients.length && !_vm.visibleOptions)?_c('span',{staticClass:"group-role-select__selected-text",on:{"click":_vm.openList}},[_vm._v(" No recipients selected ")]):_vm._e(),(_vm.recipients.length && !_vm.visibleOptions)?_vm._l((_vm.compactRecipients),function(target){return _c('span',{key:target.name,staticClass:"group-role-select__selected-role"},[_vm._v(" "+_vm._s(target.name)+" "),_c('span',{staticClass:"group-role-select__selected-role-x",on:{"click":function($event){return _vm.removeRecipient(target)}}},[_c('i',{staticClass:"cs-icons-close"})])])}):_vm._e(),(_vm.visibleOptions)?_c('app-input-search',{attrs:{"placeholder":"Search recipients"},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}}):_vm._e()],2),(!_vm.visibleOptions)?_c('span',{staticClass:"group-role-select__toggle-button",on:{"click":_vm.openList}},[_c('i',{staticClass:"cs-icons-plus"})]):_c('div',[_c('cs-button',{staticClass:"group-role-select__action-select",attrs:{"size":"small","fill":"outline"},on:{"click":_vm.closeList}},[_vm._v(" Cancel ")]),_c('cs-button',{staticClass:"group-role-select__action-select",attrs:{"size":"small"},on:{"click":_vm.saveSelection}},[_vm._v("Select")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleOptions),expression:"visibleOptions"}],staticClass:"group-role-select__list"},[_vm._l((_vm.filteredOptions),function(target){return [_c('transition',{key:target.name,attrs:{"name":"slide-fade"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.isRolled(target.role, target.parentGroupId, target.rootGroupId)
            ),expression:"\n              !isRolled(target.role, target.parentGroupId, target.rootGroupId)\n            "}],staticClass:"group-role-select__list-item",class:{
              'group-role-select__list-item--is-parent': target.isParent,
              'group-role-select__list-item--selected': _vm.isSelected(target),
            }},[(target.parentGroupId)?_c('span',{staticClass:"group-role-select__list-item-spacer",class:{
                'group-role-select__list-item-spacer--double':
                  target.rootGroupId,
              }},[_vm._v("   ")]):_vm._e(),_c('app-check-box',{attrs:{"label":target.name,"value":_vm.isSelected(target)},on:{"click":function($event){return _vm.toggle(target)}}}),(target.subgroupCount)?_c('app-expand-button',{attrs:{"is-open":_vm.isRolled(target.role, target.id)},on:{"click":function($event){return _vm.rollSubgroups(target.role, target.id)}}}):_vm._e()],1)])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }