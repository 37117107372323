var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ch-chat-button"},[_c('i',{staticClass:"ch-icons-message",class:{ selectedIcon: _vm.highlight },attrs:{"id":"navbarChatIcon","tabindex":"-1"}}),(_vm.hasUnseenMessages)?_c('div',{staticClass:"ch-new-content-indicator"}):_vm._e()]),_c('b-popover',{ref:"chatPopover",attrs:{"target":"navbarChatIcon","placement":"leftbottom","triggers":"click blur"},on:{"show":_vm.showPopover}},[_c('div',{staticClass:"chat-header menu-popover-title"},[_c('div',{staticClass:"cs-textstyle-detail-heading"},[_vm._v("Messages")]),_c('i',{staticClass:"ch-icons-new-message ch-icons",on:{"click":_vm.openNewMessageModal}})]),(!_vm.conversations)?_c('div',[_c('app-spinner')],1):_vm._e(),(_vm.conversations)?_c('div',_vm._l((_vm.filteredConversations),function(conversation,$index){return _c('conversation-item',{directives:[{name:"router-link",rawName:"v-router-link",value:({
          name: 'ConversationDetail',
          params: {
            type: _vm.type(conversation.otherParty.myRelationship),
            conversationId: conversation.conversationId,
          },
        }),expression:"{\n          name: 'ConversationDetail',\n          params: {\n            type: type(conversation.otherParty.myRelationship),\n            conversationId: conversation.conversationId,\n          },\n        }"}],key:conversation.conversationId + $index,staticClass:"message-item cursor-pointer",attrs:{"conversation":conversation},on:{"click":_vm.closeChatPopover}})}),1):_vm._e(),(
        _vm.conversations &&
        _vm.filteredConversations &&
        _vm.filteredConversations.length === 0
      )?_c('div',[_c('cs-empty-state',[_c('div',{staticClass:"empty-slot-title",attrs:{"slot":"title"},slot:"title"},[_vm._v("No Messages")]),_c('div',{staticClass:"empty-slot-desc",attrs:{"slot":"description"},slot:"description"},[_vm._v(" No one has messaged you yet. ")]),_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('cs-button',{attrs:{"size":"small"},on:{"click":_vm.openNewMessageModal}},[_vm._v("Start Conversation")])],1)])],1):_vm._e(),(
        _vm.conversations &&
        _vm.filteredConversations &&
        _vm.filteredConversations.length > 0
      )?_c('router-link',{attrs:{"to":{ name: 'Conversations', params: { type: 'friend' } }}},[_c('div',{staticClass:"menu-popover-more cs-textstyle-detail-heading",on:{"click":_vm.closeChatPopover}},[_vm._v(" View All Conversations ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }