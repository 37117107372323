export default {
  'Frequently used': {
    'selector': '⏱',
    'data': {
      'thumbs_up': '👍',
      '-1': '👎',
      'sob': '😭',
      'confused': '😕',
      'neutral_face': '😐',
      'blush': '😊',
      'heart_eyes': '😍',
    }
  },
  'People': {
    'selector': '😄',
    'data': {
      'smile': '😄',
      'smiley': '😃',
      'grinning': '😀',
      'blush': '😊',
      'wink': '😉',
      'heart_eyes': '😍',
      'kissing_heart': '😘',
      'kissing_closed_eyes': '😚',
      'kissing': '😗',
      'kissing_smiling_eyes': '😙',
      'stuck_out_tongue_winking_eye': '😜',
      'stuck_out_tongue_closed_eyes': '😝',
      'stuck_out_tongue': '😛',
      'flushed': '😳',
      'grin': '😁',
      'pensive': '😔',
      'relieved': '😌',
      'unamused': '😒',
      'disappointed': '😞',
      'persevere': '😣',
      'cry': '😢',
      'joy': '😂',
      'sob': '😭',
      'sleepy': '😪',
      'disappointed_relieved': '😥',
      'cold_sweat': '😰',
      'sweat_smile': '😅',
      'sweat': '😓',
      'weary': '😩',
      'tired_face': '😫',
      'fearful': '😨',
      'scream': '😱',
      'angry': '😠',
      'rage': '😡',
      'triumph': '😤',
      'confounded': '😖',
      'laughing': '😆',
      'yum': '😋',
      'mask': '😷',
      'sunglasses': '😎',
      'sleeping': '😴',
      'dizzy_face': '😵',
      'astonished': '😲',
      'worried': '😟',
      'frowning': '😦',
      'anguished': '😧',
      'imp': '👿',
      'open_mouth': '😮',
      'grimacing': '😬',
      'neutral_face': '😐',
      'confused': '😕',
      'hushed': '😯',
      'smirk': '😏',
      'expressionless': '😑',
      'man_with_gua_pi_mao': '👲',
      'man_with_turban': '👳',
      'cop': '👮',
      'construction_worker': '👷',
      'guardsman': '💂',
      'baby': '👶',
      'boy': '👦',
      'girl': '👧',
      'man': '👨',
      'woman': '👩',
      'older_man': '👴',
      'older_woman': '👵',
      'person_with_blond_hair': '👱',
      'angel': '👼',
      'princess': '👸',
      'smiley_cat': '😺',
      'smile_cat': '😸',
      'heart_eyes_cat': '😻',
      'kissing_cat': '😽',
      'smirk_cat': '😼',
      'scream_cat': '🙀',
      'crying_cat_face': '😿',
      'joy_cat': '😹',
      'pouting_cat': '😾',
      'japanese_ogre': '👹',
      'japanese_goblin': '👺',
      'see_no_evil': '🙈',
      'hear_no_evil': '🙉',
      'speak_no_evil': '🙊',
      'skull': '💀',
      'alien': '👽',
      'hankey': '💩',
      'fire': '🔥',
      'sparkles': '✨',
      'star2': '🌟',
      'dizzy': '💫',
      'boom': '💥',
      'anger': '💢',
      'sweat_drops': '💦',
      'droplet': '💧',
      'zzz': '💤',
      'dash': '💨',
      'ear': '👂',
      'eyes': '👀',
      'nose': '👃',
      'tongue': '👅',
      'lips': '👄',
      'thumbs_up': '👍',
      '-1': '👎',
      'ok_hand': '👌',
      'facepunch': '👊',
      'fist': '✊',
      'wave': '👋',
      'hand': '✋',
      'open_hands': '👐',
      'point_up_2': '👆',
      'point_down': '👇',
      'point_right': '👉',
      'point_left': '👈',
      'raised_hands': '🙌',
      'pray': '🙏',
      'clap': '👏',
      'muscle': '💪',
      'walking': '🚶',
      'runner': '🏃',
      'dancer': '💃',
      'couple': '👫',
      'family': '👪',
      'couplekiss': '💏',
      'couple_with_heart': '💑',
      'dancers': '👯',
      'ok_woman': '🙆',
      'no_good': '🙅',
      'information_desk_person': '💁',
      'raising_hand': '🙋',
      'massage': '💆',
      'haircut': '💇',
      'nail_care': '💅',
      'bride_with_veil': '👰',
      'person_with_pouting_face': '🙎',
      'person_frowning': '🙍',
      'bow': '🙇',
      'tophat': '🎩',
      'crown': '👑',
      'womans_hat': '👒',
      'athletic_shoe': '👟',
      'mans_shoe': '👞',
      'sandal': '👡',
      'high_heel': '👠',
      'boot': '👢',
      'shirt': '👕',
      'necktie': '👔',
      'womans_clothes': '👚',
      'dress': '👗',
      'running_shirt_with_sash': '🎽',
      'jeans': '👖',
      'kimono': '👘',
      'bikini': '👙',
      'briefcase': '💼',
      'handbag': '👜',
      'pouch': '👝',
      'purse': '👛',
      'eyeglasses': '👓',
      'ribbon': '🎀',
      'closed_umbrella': '🌂',
      'lipstick': '💄',
      'yellow_heart': '💛',
      'blue_heart': '💙',
      'purple_heart': '💜',
      'green_heart': '💚',
      'broken_heart': '💔',
      'heartpulse': '💗',
      'heartbeat': '💓',
      'two_hearts': '💕',
      'sparkling_heart': '💖',
      'revolving_hearts': '💞',
      'cupid': '💘',
      'love_letter': '💌',
      'kiss': '💋',
      'ring': '💍',
      'gem': '💎',
      'bust_in_silhouette': '👤',
      'speech_balloon': '💬',
      'footprints': '👣',
    }
  },
  'Nature': {
    'selector': '🌿',
    'data': {
    'dog': '🐶',
    'wolf': '🐺',
    'cat': '🐱',
    'mouse': '🐭',
    'hamster': '🐹',
    'rabbit': '🐰',
    'frog': '🐸',
    'tiger': '🐯',
    'koala': '🐨',
    'bear': '🐻',
    'pig': '🐷',
    'pig_nose': '🐽',
    'cow': '🐮',
    'boar': '🐗',
    'monkey_face': '🐵',
    'monkey': '🐒',
    'horse': '🐴',
    'sheep': '🐑',
    'elephant': '🐘',
    'panda_face': '🐼',
    'penguin': '🐧',
    'bird': '🐦',
    'baby_chick': '🐤',
    'hatched_chick': '🐥',
    'hatching_chick': '🐣',
    'chicken': '🐔',
    'snake': '🐍',
    'turtle': '🐢',
    'bug': '🐛',
    'bee': '🐝',
    'ant': '🐜',
    'beetle': '🐞',
    'snail': '🐌',
    'octopus': '🐙',
    'shell': '🐚',
    'tropical_fish': '🐠',
    'fish': '🐟',
    'dolphin': '🐬',
    'whale': '🐳',
    'racehorse': '🐎',
    'dragon_face': '🐲',
    'blowfish': '🐡',
    'camel': '🐫',
    'poodle': '🐩',
    'feet': '🐾',
    'bouquet': '💐',
    'cherry_blossom': '🌸',
    'tulip': '🌷',
    'four_leaf_clover': '🍀',
    'rose': '🌹',
    'sunflower': '🌻',
    'hibiscus': '🌺',
    'maple_leaf': '🍁',
    'leaves': '🍃',
    'fallen_leaf': '🍂',
    'herb': '🌿',
    'ear_of_rice': '🌾',
    'mushroom': '🍄',
    'cactus': '🌵',
    'palm_tree': '🌴',
    'chestnut': '🌰',
    'seedling': '🌱',
    'blossom': '🌼',
    'new_moon': '🌑',
    'first_quarter_moon': '🌓',
    'moon': '🌔',
    'full_moon': '🌕',
    'first_quarter_moon_with_face': '🌛',
    'crescent_moon': '🌙',
    'earth_asia': '🌏',
    'volcano': '🌋',
    'milky_way': '🌌',
    'stars': '🌠',
    'partly_sunny': '⛅',
    'snowman': '⛄',
    'cyclone': '🌀',
    'foggy': '🌁',
    'rainbow': '🌈',
    'ocean': '🌊',
    }
  },
  'Objects': {
    'selector': '🎁',
    'data' : {
      'bamboo': '🎍',
      'gift_heart': '💝',
      'dolls': '🎎',
      'school_satchel': '🎒',
      'mortar_board': '🎓',
      'flags': '🎏',
      'fireworks': '🎆',
      'sparkler': '🎇',
      'wind_chime': '🎐',
      'rice_scene': '🎑',
      'jack_o_lantern': '🎃',
      'ghost': '👻',
      'santa': '🎅',
      'christmas_tree': '🎄',
      'gift': '🎁',
      'tanabata_tree': '🎋',
      'tada': '🎉',
      'confetti_ball': '🎊',
      'balloon': '🎈',
      'crossed_flags': '🎌',
      'crystal_ball': '🔮',
      'movie_camera': '🎥',
      'camera': '📷',
      'video_camera': '📹',
      'vhs': '📼',
      'cd': '💿',
      'dvd': '📀',
      'minidisc': '💽',
      'floppy_disk': '💾',
      'computer': '💻',
      'iphone': '📱',
      'telephone_receiver': '📞',
      'pager': '📟',
      'fax': '📠',
      'satellite': '📡',
      'tv': '📺',
      'radio': '📻',
      'loud_sound': '🔊',
      'bell': '🔔',
      'loudspeaker': '📢',
      'mega': '📣',
      'hourglass_flowing_sand': '⏳',
      'hourglass': '⌛',
      'alarm_clock': '⏰',
      'watch': '⌚',
      'unlock': '🔓',
      'lock': '🔒',
      'lock_with_ink_pen': '🔏',
      'closed_lock_with_key': '🔐',
      'key': '🔑',
      'mag_right': '🔎',
      'bulb': '💡',
      'flashlight': '🔦',
      'electric_plug': '🔌',
      'battery': '🔋',
      'mag': '🔍',
      'bath': '🛀',
      'toilet': '🚽',
      'wrench': '🔧',
      'nut_and_bolt': '🔩',
      'hammer': '🔨',
      'door': '🚪',
      'smoking': '🚬',
      'bomb': '💣',
      'gun': '🔫',
      'hocho': '🔪',
      'pill': '💊',
      'syringe': '💉',
      'moneybag': '💰',
      'yen': '💴',
      'dollar': '💵',
      'credit_card': '💳',
      'money_with_wings': '💸',
      'calling': '📲',
      'e-mail': '📧',
      'inbox_tray': '📥',
      'outbox_tray': '📤',
      'envelope_with_arrow': '📩',
      'incoming_envelope': '📨',
      'mailbox': '📫',
      'mailbox_closed': '📪',
      'postbox': '📮',
      'package': '📦',
      'memo': '📝',
      'page_facing_up': '📄',
      'page_with_curl': '📃',
      'bookmark_tabs': '📑',
      'bar_chart': '📊',
      'chart_with_upwards_trend': '📈',
      'chart_with_downwards_trend': '📉',
      'scroll': '📜',
      'clipboard': '📋',
      'date': '📅',
      'calendar': '📆',
      'card_index': '📇',
      'file_folder': '📁',
      'open_file_folder': '📂',
      'pushpin': '📌',
      'paperclip': '📎',
      'straight_ruler': '📏',
      'triangular_ruler': '📐',
      'closed_book': '📕',
      'green_book': '📗',
      'blue_book': '📘',
      'orange_book': '📙',
      'notebook': '📓',
      'notebook_with_decorative_cover': '📔',
      'ledger': '📒',
      'books': '📚',
      'book': '📖',
      'bookmark': '🔖',
      'name_badge': '📛',
      'newspaper': '📰',
      'art': '🎨',
      'clapper': '🎬',
      'microphone': '🎤',
      'headphones': '🎧',
      'musical_score': '🎼',
      'musical_note': '🎵',
      'notes': '🎶',
      'musical_keyboard': '🎹',
      'violin': '🎻',
      'trumpet': '🎺',
      'saxophone': '🎷',
      'guitar': '🎸',
      'space_invader': '👾',
      'video_game': '🎮',
      'black_joker': '🃏',
      'flower_playing_cards': '🎴',
      'mahjong': '🀄',
      'game_die': '🎲',
      'dart': '🎯',
      'football': '🏈',
      'basketball': '🏀',
      'soccer': '⚽',
      'baseball': '⚾',
      'tennis': '🎾',
      '8ball': '🎱',
      'bowling': '🎳',
      'golf': '⛳',
      'checkered_flag': '🏁',
      'trophy': '🏆',
      'ski': '🎿',
      'snowboarder': '🏂',
      'swimmer': '🏊',
      'surfer': '🏄',
      'fishing_pole_and_fish': '🎣',
      'tea': '🍵',
      'sake': '🍶',
      'beer': '🍺',
      'beers': '🍻',
      'cocktail': '🍸',
      'tropical_drink': '🍹',
      'wine_glass': '🍷',
      'fork_and_knife': '🍴',
      'pizza': '🍕',
      'hamburger': '🍔',
      'fries': '🍟',
      'poultry_leg': '🍗',
      'meat_on_bone': '🍖',
      'spaghetti': '🍝',
      'curry': '🍛',
      'fried_shrimp': '🍤',
      'bento': '🍱',
      'sushi': '🍣',
      'fish_cake': '🍥',
      'rice_ball': '🍙',
      'rice_cracker': '🍘',
      'rice': '🍚',
      'ramen': '🍜',
      'stew': '🍲',
      'oden': '🍢',
      'dango': '🍡',
      'egg': '🍳',
      'bread': '🍞',
      'doughnut': '🍩',
      'custard': '🍮',
      'icecream': '🍦',
      'ice_cream': '🍨',
      'shaved_ice': '🍧',
      'birthday': '🎂',
      'cake': '🍰',
      'cookie': '🍪',
      'chocolate_bar': '🍫',
      'candy': '🍬',
      'lollipop': '🍭',
      'honey_pot': '🍯',
      'apple': '🍎',
      'green_apple': '🍏',
      'tangerine': '🍊',
      'cherries': '🍒',
      'grapes': '🍇',
      'watermelon': '🍉',
      'strawberry': '🍓',
      'peach': '🍑',
      'melon': '🍈',
      'banana': '🍌',
      'pineapple': '🍍',
      'sweet_potato': '🍠',
      'eggplant': '🍆',
      'tomato': '🍅',
      'corn': '🌽',
    }
  },
  'Places': {
    'selector': '🌄',
    'data': {
      'house': '🏠',
      'house_with_garden': '🏡',
      'school': '🏫',
      'office': '🏢',
      'post_office': '🏣',
      'hospital': '🏥',
      'bank': '🏦',
      'convenience_store': '🏪',
      'love_hotel': '🏩',
      'hotel': '🏨',
      'wedding': '💒',
      'church': '⛪',
      'department_store': '🏬',
      'city_sunrise': '🌇',
      'city_sunset': '🌆',
      'japanese_castle': '🏯',
      'european_castle': '🏰',
      'tent': '⛺',
      'factory': '🏭',
      'tokyo_tower': '🗼',
      'japan': '🗾',
      'mount_fuji': '🗻',
      'sunrise_over_mountains': '🌄',
      'sunrise': '🌅',
      'night_with_stars': '🌃',
      'statue_of_liberty': '🗽',
      'bridge_at_night': '🌉',
      'carousel_horse': '🎠',
      'ferris_wheel': '🎡',
      'fountain': '⛲',
      'roller_coaster': '🎢',
      'ship': '🚢',
      'boat': '⛵',
      'speedboat': '🚤',
      'rocket': '🚀',
      'seat': '💺',
      'station': '🚉',
      'bullettrain_side': '🚄',
      'bullettrain_front': '🚅',
      'metro': '🚇',
      'railway_car': '🚃',
      'bus': '🚌',
      'blue_car': '🚙',
      'car': '🚗',
      'taxi': '🚕',
      'truck': '🚚',
      'rotating_light': '🚨',
      'police_car': '🚓',
      'fire_engine': '🚒',
      'ambulance': '🚑',
      'bike': '🚲',
      'barber': '💈',
      'busstop': '🚏',
      'ticket': '🎫',
      'traffic_light': '🚥',
      'construction': '🚧',
      'beginner': '🔰',
      'fuelpump': '⛽',
      'izakaya_lantern': '🏮',
      'slot_machine': '🎰',
      'moyai': '🗿',
      'circus_tent': '🎪',
      'performing_arts': '🎭',
      'round_pushpin': '📍',
      'triangular_flag_on_post': '🚩',
    }
  },
  'Symbols': {
    'selector': '🔢',
    'data': {
      'keycap_ten': '🔟',
      '1234': '🔢',
      'symbols': '🔣',
      'capital_abcd': '🔠',
      'abcd': '🔡',
      'abc': '🔤',
      'arrow_up_small': '🔼',
      'arrow_down_small': '🔽',
      'rewind': '⏪',
      'fast_forward': '⏩',
      'arrow_double_up': '⏫',
      'arrow_double_down': '⏬',
      'ok': '🆗',
      'new': '🆕',
      'up': '🆙',
      'cool': '🆒',
      'free': '🆓',
      'ng': '🆖',
      'signal_strength': '📶',
      'cinema': '🎦',
      'koko': '🈁',
      'u6307': '🈯',
      'u7a7a': '🈳',
      'u6e80': '🈵',
      'u5408': '🈴',
      'u7981': '🈲',
      'ideograph_advantage': '🉐',
      'u5272': '🈹',
      'u55b6': '🈺',
      'u6709': '🈶',
      'u7121': '🈚',
      'restroom': '🚻',
      'mens': '🚹',
      'womens': '🚺',
      'baby_symbol': '🚼',
      'wc': '🚾',
      'no_smoking': '🚭',
      'u7533': '🈸',
      'accept': '🉑',
      'cl': '🆑',
      'sos': '🆘',
      'id': '🆔',
      'no_entry_sign': '🚫',
      'underage': '🔞',
      'no_entry': '⛔',
      'negative_squared_cross_mark': '❎',
      'white_check_mark': '✅',
      'heart_decoration': '💟',
      'vs': '🆚',
      'vibration_mode': '📳',
      'mobile_phone_off': '📴',
      'ab': '🆎',
      'diamond_shape_with_a_dot_inside': '💠',
      'ophiuchus': '⛎',
      'six_pointed_star': '🔯',
      'atm': '🏧',
      'chart': '💹',
      'heavy_dollar_sign': '💲',
      'currency_exchange': '💱',
      'x': '❌',
      'exclamation': '❗',
      'question': '❓',
      'grey_exclamation': '❕',
      'grey_question': '❔',
      'o': '⭕',
      'top': '🔝',
      'end': '🔚',
      'back': '🔙',
      'on': '🔛',
      'soon': '🔜',
      'arrows_clockwise': '🔃',
      'clock12': '🕛',
      'clock1': '🕐',
      'clock2': '🕑',
      'clock3': '🕒',
      'clock4': '🕓',
      'clock5': '🕔',
      'clock6': '🕕',
      'clock7': '🕖',
      'clock8': '🕗',
      'clock9': '🕘',
      'clock10': '🕙',
      'clock11': '🕚',
      'heavy_plus_sign': '➕',
      'heavy_minus_sign': '➖',
      'heavy_division_sign': '➗',
      'white_flower': '💮',
      '100': '💯',
      'radio_button': '🔘',
      'link': '🔗',
      'curly_loop': '➰',
      'trident': '🔱',
      'small_red_triangle': '🔺',
      'black_square_button': '🔲',
      'white_square_button': '🔳',
      'red_circle': '🔴',
      'large_blue_circle': '🔵',
      'small_red_triangle_down': '🔻',
      'white_large_square': '⬜',
      'black_large_square': '⬛',
      'large_orange_diamond': '🔶',
      'large_blue_diamond': '🔷',
      'small_orange_diamond': '🔸',
      'small_blue_diamond': '🔹',
    }
  },
}