<template>
  <app-modal
    :show="showModal"
    class="ch-pay-modal"
    :title="'Pay for Extended Video Features!'"
    @close="onClose"
    size="small"
  >
    <div class="ch-pay-modal__body">
      <p>
        At CloutHub we’ve always prioritized providing a robust experience to
        our valued users. As our platform continues to grow and evolve, we’ve
        recognized the need to implement small charges for larger content
        uploads and storage. Your support ensures the continued availability and
        security of your content and our platform. Thank you for helping us grow
        together!
      </p>
      <payment 
        @success-payment="onSuccessPayment" 
        @on-success-transaction="$emit('on-success-transaction', $event)" 
      />
    </div>
    <!-- <div slot="footer" style="text-align: right">
      <cs-button size="small" @click="onClose" variant="secondary">Close</cs-button>
    </div> -->
  </app-modal>
</template>

<script>
import AppModal from '@/components/general/Modal.vue';
import Payment from '@/components/payment/Payment.vue';

export default {
  name: 'PayAnnounceModal',
  components: { AppModal, Payment },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSuccessPayment(event) {
      this.$emit('success-payment', event);
    },
    onClose() {
      this.$emit('close-modal');
    },
  },
};
</script>
<style>
.ch-pay-modal .ch-modal__popup {
  max-width: 500px;
  width: 500px;
}
</style>