<template>
  <div
    class="ch-post-info"
    :class="preview ? 'no-click' : ''"
    v-if="isNavBarVisible"
  >
    <post-header
      :post="targetPost"
      :preview="preview"
      :can-delete="canDelete"
    ></post-header>
    <div
      v-if="targetPost.bodyText"
      class="post-message cs-textstyle-paragraph cursor-pointer"
      @click="$emit('on-show-post-modal')"
    >
      <span v-for="(data, i) in displayBody" :key="i">
        <!-- Normal text in body -->
        <span v-if="data.type === 'text'">{{ data.word }} </span>
        <!-- Link in post poby -->
        <a
          v-if="data.type === 'link'"
          class="ch-link"
          :href="data.link"
          target="_blank"
        >
          {{ data.word }}
        </a>
        <!-- mentions in post body -->
        <span
          v-if="data.type === 'mention'"
          v-router-link="{
            name: 'UProfile',
            params: { username: formatUsername(data.word) },
          }"
          class="ch-user-mention"
          >{{ data.word }}
        </span>
      </span>
    </div>
    <img
      v-if="isTranslatingPost"
      src="@/assets/images/translation_icon-orange.svg"
      class="translate-button"
      @click="$emit('showTranslatedPost')"
    />
    <div
      v-if="isShowingTranslation && !targetPost.sharedPost"
      class="ch-translation-wrapper"
    >
      <img
        v-if="isHidingTranslatePost"
        class="translate-button"
        src="@/assets/images/translation_icon-orange.svg"
        @click="hideTranslatedPost"
      />
      <div class="post-message cs-textstyle-paragraph message-margin">
        <span v-for="(data, i) in displayTranslatedBody" :key="i + data.word">
          <span v-if="data.type === 'text'">{{ data.word }} </span>

          <a
            v-if="data.type === 'link'"
            class="ch-link"
            :href="data.link"
            target="_blank"
          >
            {{ data.word }}
          </a>

          <span
            v-if="data.type === 'mention'"
            v-router-link="{
              name: 'UProfile',
              params: { username: formatUsername(data.word) },
            }"
            class="ch-user-mention"
            >{{ data.word }}
          </span>
        </span>
      </div>
      <app-spinner v-if="loadingTranslation" size="small" class="ch-spinner" />
    </div>
    <post-tags
      v-if="targetPost.tags && targetPost.tags.length"
      :tags="targetPost.tags"
    />
    <cs-small-tag v-if="targetPost.location" variant="default">
      <i class="cs-icons-marker-filled"></i>
      {{ targetPost.location }}
    </cs-small-tag>
  </div>
</template>
<script>
import PostHeader from '@/components/post/items/PostHeader.vue';
import PostTags from '@/components/post/items/PostTags.vue';
import AppSpinner from '@/components/general/Spinner.vue';
export default {
  name: 'PostInfo',
  components: {
    PostHeader,
    PostTags,
    AppSpinner,
  },
  props: {
    isNavBarVisible: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    targetPost: {
      type: Object,
      required: true,
    },
    redirectToPostDetailedPage: {
      type: Function,
    },
    isTranslatingPost: {
      type: Boolean,
      default: false,
    },
    isShowingTranslation: {
      type: Boolean,
      default: false,
    },
    isHidingTranslatePost: {
      type: Boolean,
      default: false,
    },
    hideTranslatedPost: {
      type: Function,
    },
    displayTranslatedBody: {
      type: Array,
    },
    formatUsername: {
      type: Function,
    },
    loadingTranslation: {
      type: Boolean,
      default: false,
    },
    getClickableLink: {
      type: Function,
    },
  },
  computed: {
    displayBody() {
      const finalArray = [];
      const postLines = this.targetPost.bodyText.split(/\n/g);
      const previewLink = this.targetPost.linkPreview
        ? this.targetPost.linkPreview.originalLink
        : null;
      postLines.forEach((line, index) => {
        const isNotLastLine = index < postLines.length - 1;
        const bodyArray = line.split(' ');
        bodyArray.forEach((data) => {
          const link = data.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g
          );
          if (link) {
            const email = data.match(/.*@.*/);
            if (data !== previewLink || email) {
              let clicableLink;
              if (email) clicableLink = `mailto:${data}`;
              else clicableLink = this.getClickableLink(data);
              finalArray.push({
                type: 'link',
                word: data,
                link: clicableLink,
              });
            }
          } else if (data.startsWith('@')) {
            let matched = false;
            this.targetPost.mentions.forEach((mention) => {
              if (mention.username === data) {
                finalArray.push({
                  type: 'mention',
                  word: data,
                });
                matched = true;
              }
            });
            if (!matched) {
              finalArray.push({
                type: 'text',
                word: data,
              });
            }
          } else {
            finalArray.push({
              type: 'text',
              word: data,
            });
          }
        });

        if (isNotLastLine) {
          finalArray.push({
            type: 'text',
            word: '\n',
          });
        }
      });
      this.checkTranslationDemand(finalArray);
      return finalArray;
    },
  },
  methods: {
    checkTranslationDemand(finalArray) {
      this.disableTranslateButton = finalArray.reduce(
        (accumulator, postElement) =>
          accumulator && !!(postElement.word === '' || postElement.link),
        true
      );
    },
  },
};
</script>
