<template>
  <b-form class="credit-form" @submit.prevent="onSubmit">
    <b-row>
      <b-col cols="6">
        <cs-input
          v-model="firstName"
          class="w-100"
          placeholder="Enter First name"
          label="First Name"
          required
        ></cs-input>
      </b-col>
      <b-col cols="6">
        <cs-input
          v-model="lastName"
          class="w-100"
          placeholder="Enter Last Name"
          label="Last Name"
          required
        ></cs-input>
      </b-col>
      <b-col cols="6" class="mt-3">
        <cs-input
          v-model="email"
          type="email"
          class="w-100"
          placeholder="Enter Email"
          label="Email"
          required
        ></cs-input>
      </b-col>
      <b-col cols="6" class="mt-3">
        <cs-input
          v-model="amount"
          class="w-100"
          readonly
          disabled
          placeholder="Enter Amount"
          label="Amount"
          required
        ></cs-input>
      </b-col>
      <b-col cols="12" class="mt-3">
        <cs-input
          v-model="card"
          class="w-100"
          placeholder="Enter Card Number"
          label="Card Number"
          type="number"
          :min-length="16"
          :max-length="16"
          required
        ></cs-input>
      </b-col>
      <b-col cols="8" class="mt-3">
        <div
          class="cs-textstyle-label-text cs-input__label-text"
          style="margin-bottom: 8px"
        >
          Expiration Date
        </div>
        <div class="expiration">
          <cs-select
            v-model="cardMonth"
            :options="monthOption"
            placeholder="Select Month"
          ></cs-select>
          <cs-select
            v-model="cardYear"
            :options="yearOption"
            placeholder="Select Year"
          ></cs-select>
        </div>
      </b-col>
      <b-col cols="4" class="mt-3">
        <cs-input
          v-model="cvv"
          placeholder="Enter CVV"
          label="CVV"
          type="password"
          :min-length="3"
          :max-length="4"
          required
        ></cs-input>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between mt-3">
      <cs-button
        class="custom-btn"
        size="small"
        corners="straight"
        type="button"
        @click="$emit('onBackToPlans')"
        >Back
      </cs-button>
      <cs-button size="small" corners="straight" type="submit"
        >Pay Now</cs-button
      >
    </div>
  </b-form>
</template>
<script>
import axios from 'axios';
import $auth from '@/services/auth';
import $popups from '@/services/popups';

export default {
  name: 'CreditForm',
  filters: {
    formatCardNumber(value) {
      return value ? value.match(/.{1,4}/g).join(' ') : '';
    },
  },
  props: {
    isContentProvider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      amount: this.isContentProvider ? 1.0 : 2.99,
      card: '',
      cvv: '',
      cardMonth: '',
      cardYear: '',
      ip: '',
      userInfo: null,
    };
  },
  computed: {
    exp() {
      return `${this.cardMonth + this.cardYear}`;
    },
    monthOption() {
      const month = [];
      for (let i = 1; i <= 12; i++) {
        const formatMonth = i < 10 ? `0${i}` : i;
        month.push({ label: formatMonth, value: formatMonth });
      }
      return month;
    },
    yearOption() {
      const years = [];
      const minCardYear = new Date().getFullYear();
      for (let i = 0; i < 12; i++) {
        const formatYear = minCardYear + i;
        years.push({
          label: formatYear,
          value: formatYear.toString().substring(2, 4),
        });
      }
      return years;
    },
  },
  async mounted() {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        this.ip = data.ip;
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
    this.user = await $auth.getUser();
    console.log(this.user);
  },
  methods: {
    updateValue(e) {
      this.cardNumber = e.target.value.replace(/ /g, '');
    },
    onSubmit() {
      console.log('on submit');
      try {
        const payload = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          amount: Number(this.amount),
          card: this.card,
          exp: this.exp,
          cvv: `${this.cvv}`,
          address: this.user && this.user.location ? this.user.location.address || '' : '',
          city: this.user && this.user.location ? this.user.location.city || '' : '',
          state: this.user && this.user.location ? this.user.location.state || '' : '',
          country: this.user && this.user.location ? this.user.location.country || '' : '',
          pincode: this.user && this.user.location ? this.user.location.pincode || '' : '',
          ip_address: this.ip,
        };
        const response = axios
          .post('https://ch-pay.clouthub.com/nmi.php', payload)
          .then((response) => {
            // Access data here
            console.log('inside loop:', response.data);
            if (
              response.data &&
              response.data.message &&
              response.data.message[0] === 'Approved'
            ) {
              this.$emit('on-success', { token: 'test' });
            } else {
              $popups.alert({
                title: 'Error',
                message: response.data.message[0],
              });
            }
          })
          .catch((error) => {
            // Handle errors
            console.error(error);
          });

        /* const response = {
          data : {
              "success": true,
              "message": [
                  "Approved"
              ]
          }
        }; */

        console.log('gateway response: ', response);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style scoped>
.custom-btn {
  background: var(--cs-gray-04) !important;
  border: 1px solid var(--cs-gray-04) !important;
}
.expiration {
  display: flex;
  gap: 0 5px;
}
.active-btn {
  margin-top: 20px;
  border-radius: 3px !important;
}
</style>
