<template>
  <div class="payment-plans">
    <div class="plans-content">
      <div
        :class="[isContentProvider ? 'active-plans' : '', 'plans-card']"
        @click="$emit('onSelectPlans', true)"
      >
        <p class="plans-title">Large File Upload</p>
        <div class="plans-check">
          <img
            v-if="isContentProvider"
            src="@/assets/icons/check.png"
            alt="check-icon"
            width="16"
          />
          <div v-else class="uncheck-box"></div>
        </div>
        <h4>$1/Upload</h4>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Single File Uploads</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">$1 per upload</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Video or Images Files Larger Than 2 GB</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Videos Longer Than 1 Hour</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Store Files Securely and Safely</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Download Files Any Time.</span>
        </div>
      </div>
      <div
        :class="[!isContentProvider ? 'active-plans' : '', 'plans-card']"
        @click="$emit('onSelectPlans', false)"
      >
        <p class="plans-title">Volume Content Provider</p>
        <div class="plans-check">
          <img
            v-if="!isContentProvider"
            src="@/assets/icons/check.png"
            alt="check-icon"
            width="16"
          />
          <div v-else class="uncheck-box"></div>
        </div>
        <h4>$2.99/month</h4>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Unlimited File Uploads</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">No size limited on video or images files</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">No show length limit</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">100GB of Store Secure and Safe Storage</span>
        </div>
        <div class="feature-item">
          <img src="@/assets/icons/check-mark.png" alt="check-icon" />
          <span class="ml-2">Viewers Download Files Any Time</span>
        </div>
      </div>
    </div>
    <cs-button
      class="billing-btn"
      size="small"
      corners="straight"
      @click="$emit('onShowCreditForm')"
      >Billing</cs-button
    >
  </div>
</template>
<script>
export default {
  props: {
    isContentProvider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.plans-content {
  display: flex;
  justify-content: space-around;
  gap: 0 10px;
}
.plans-title {
  font-weight: bold;
  font-size: 14px;
}
.active-plans {
  border: 1px solid var(--cs-primary-base) !important;
}
.plans-card {
  position: relative;
  width: 50%;
  padding: 12px;
  border: solid 1px var(--cs-gray-02);
  border-radius: 15px;
  cursor: pointer;
}
.billing-btn {
  width: 100%;
  margin-top: 10px;
  border-radius: 3px !important;
}
.plans-check {
  position: absolute;
  right: 10px;
  top: 8px;
}
.uncheck-box {
  width: 16px;
  height: 16px;
  margin-top: 5px;
  border: solid 1px var(--cs-gray-02);
  border-radius: 50%;
}
.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
}
.feature-item img {
  margin-top: 5px;
}
.feature-item span {
  margin-left: 8px;
}
</style>
