<template>
  <app-modal
    :show="showCoverModal"
    class="cover-modal"
    title="Channel Cover"
    @close="onCloseCoverModal()"
  >
    <div v-if="isCropped" class="cropped-img-container">
      <img v-if="isCropped" :src="cover.dataurl" class="cropped-img" />
      <i
        class="cs-icons-delete cropped-img__delete"
        :class="{ 'cropped-img__delete--custom': fromChannelHeader }"
        @click="remove"
      />
    </div>
    <app-image-input
      v-if="!isCropped"
      v-model="cover"
      label="Upload Channel Cover (required ratio: 5.75 : 1)"
      :enforce-ratio="5.75 / 1"
      @input="imageSelected"
    />
    <div class="preset">
      <label><b>OR: Choose a Preset</b></label>
    </div>
    <div class="header-image-container">
      <app-img
        v-for="(banner, index) in randomCovers"
        :key="index"
        :src="banner"
        class="header-image"
        :class="{ 'header-image__selected': index === selectedIndex }"
        @click="selectBannerImg(banner, index)"
      />
    </div>

    <div slot="footer" class="cover-footer">
      <cs-button
        size="small"
        variant="secondary"
        fill="outline"
        @click="onCloseCoverModal"
        >Cancel</cs-button
      >
      <cs-button size="small" @click.stop="sendSelectedCover">Done</cs-button>
    </div>

    <cs-spinner
      v-if="bannerSaving && fromChannelHeader"
      class="avatar-spinner"
    />
  </app-modal>
</template>

<script>
import $videosApi from '@/api/videos';
import $upload from '@/services/upload';

import AppModal from '@/components/general/Modal.vue';
import AppImg from '@/components/general/Img.vue';
import AppImageInput from '@/components/general/ImageInput.vue';
import AppImageCropModal from '@/components/connections/editor/ImageCropModal.vue';

const dataURLtoFile = async (dataURL, filename) => {
  const blob = await fetch(dataURL).then((r) => r.blob());
  const file = new File([blob], filename, {
    lastModified: Date.now(),
    type: blob.type,
  });
  file.dataurl = URL.createObjectURL(blob);
  return file;
};
const baseURL = 'https://assets.app.clouthub.com/';
const numberOfImages = 41;

const randomCovers = Array.from({ length: numberOfImages }, (_, index) => {
    return `${baseURL}${index + 1}.png`;
});

export default {
  components: {
    AppModal,
    AppImg,
    AppImageInput,
    AppImageCropModal,
  },
  props: {
    showCoverModal: {
      type: Boolean,
      required: true,
    },
    coverImage: {
      type: String,
      required: false,
      default: null,
    },
    fromChannelHeader: {
      type: Boolean,
      default: false,
    },
    channelName: {
      type: String,
      required: false,
      default: '',
    },
    userId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      randomCovers,
      selectedIndex: null,
      selectedBanner: null,
      cover: null,
      selectedBannerSrc: null,
      isCropped: false,
      channelCover: null,
      bannerSaving: false,
    };
  },
  mounted() {
    if (this.coverImage) {
      const coverImg = this.coverImage;
      const index = this.randomCovers.indexOf(coverImg);
      if (index >= 0) {
        this.selectedIndex = index;
      } else {
        this.cover = coverImg;
      }
    }
  },
  methods: {
    onCloseCoverModal() {
      if (this.showCoverModal) {
        this.$emit('close-cover-modal');
      }
    },
    imageSelected() {
      this.selectedIndex = null;
      this.selectedBanner = null;
    },
    selectBannerImg(url, index) {
      this.selectedIndex = index;
      this.selectedBanner = url;
      this.isCropped = false;
      this.cover = null;
    },
    sendSelectedCover() {
      const cover = {
        selectedIndex: this.selectedIndex,
        selectedBanner: this.selectedBanner,
        cover: this.cover,
      };
      if (this.fromChannelHeader) {
        this.uploadSelectedCover();
      } else {
        this.$emit('close-cover-modal', cover);
      }
    },
    async uploadSelectedCover() {
      this.bannerSaving = true;
      if (this.selectedBanner && this.selectedIndex) {
        this.channelCover = this.selectedBanner;
      } else {
        const images = await $upload.uploadImages([this.cover]);
        this.channelCover =
          images[0] && images[0].url ? images[0].url : this.cover;
      }
      const vars = {
        channelCover: this.channelCover,
      };
      await $videosApi.updateChannel(vars);
      this.$emit('refresh');
      this.bannerSaving = false;
      this.onCloseCoverModal();
    },
    remove() {
      this.isCropped = false;
      this.cover = '';
    },
  },
};
</script>

<style scoped>
.header-image-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}
.header-image {
  margin-right: 5px;
  width: 450px;
}
.header-image__selected {
  border: 3px solid var(--cs-primary-base);
}
.preset {
  margin: 10px 0px;
}
.cover-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.cropped-img {
  max-width: 450px;
}
.cropped-img-container {
  position: relative;
}
.cropped-img__delete {
  position: absolute;
  top: 5px;
  right: 100px;
  font-size: 18px;
  backdrop-filter: blur(3px);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.cropped-img__delete--custom {
  right: 250px;
}
</style>
