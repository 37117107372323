<template>
  <div>
    <b-container fluid="xl">
      <b-row>
        <b-col md="3">
          <app-page-menu title="People">
            <app-page-menu-item
              v-for="item in menuItems"
              :key="item.view"
              :label="item.label"
              :to="{ name: item.name, params: { view: item.view } }"
            />
          </app-page-menu>
          <iframe id='a7440009' name='a7440009' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=61&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='250' height='250' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a71e96c0&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=61&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a71e96c0' border='0' alt='' /></a></iframe>
          <iframe id='a10571f8' name='a10571f8' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=62&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='250' height='250' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a8641e77&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=62&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a8641e77' border='0' alt='' /></a></iframe>
          <iframe id='a910f989' name='a910f989' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=63&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='250' height='250' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a59192de&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=63&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a59192de' border='0' alt='' /></a></iframe>
          <iframe id='a8b62a71' name='a8b62a71' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=64&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='250' height='250' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a7110cfd&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=64&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a7110cfd' border='0' alt='' /></a></iframe>
        </b-col>
        <b-col md="9">
          <router-view :key="$route.fullPath" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import AppPageMenu from '@/components/general/PageMenu.vue';
import AppPageMenuItem from '@/components/general/PageMenuItem.vue';

export default {
  components: {
    AppPageMenu,
    AppPageMenuItem,
  },
  data() {
    return {
      menuItems: [
        { name: 'UserList', view: 'recommended', label: 'Recommended' },
        { name: 'ConnectionsFind', view: 'find', label: 'Find' },
        { name: 'UserList', view: 'friends', label: 'Friends' },
        { name: 'UserList', view: 'following', label: 'Following' },
        { name: 'UserList', view: 'followers', label: 'Followers' },
      ],
    };
  },
  computed: {
    me() {
      return {
        id: $auth.getUserId(),
      };
    },
  },
};
</script>
