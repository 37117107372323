import { render, staticRenderFns } from "./AdSlot.vue?vue&type=template&id=71dbec36&scoped=true&"
import script from "./AdSlot.vue?vue&type=script&lang=js&"
export * from "./AdSlot.vue?vue&type=script&lang=js&"
import style0 from "./AdSlot.vue?vue&type=style&index=0&id=71dbec36&scoped=true&lang=css&"
import style1 from "./AdSlot.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71dbec36",
  null
  
)

export default component.exports