<template>
  <b-container fluid="xl" :class="{ 'px-0': true }">
    <!-- 'container-small': isSmallContainer -->
    <b-row class="row">
      <!-- Sidebar -->
      <b-col>
        <cs-button
          class="key-button mb-3"
          block
          @click="showGroupEditor = true"
        >
          Create Group
        </cs-button>

        <app-page-menu>
          <app-page-menu-item
            label="My Groups"
            :to="{ name: 'MyGroupsList' }"
          />
          <app-page-menu-item label="For Me" :to="{ name: 'GroupsForMe' }" />
          <app-page-menu-item
            label="Find a group"
            :to="{ name: 'FindGroup' }"
          />
          <app-page-menu-item
            label="Recommended"
            :to="{ name: 'RecommendedGroupsList' }"
          />
        </app-page-menu>

       <!--  <iframe
          id="ab5fd737"
          name="ab5fd737"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=57&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="250"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=a0641de5&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=57&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a0641de5"
              border="0"
              alt="" /></a
        ></iframe>
        <iframe
          id="a9d3789d"
          name="a9d3789d"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=58&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="250"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=aff10e5e&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=58&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=aff10e5e"
              border="0"
              alt="" /></a
        ></iframe>
        <iframe
          id="a4c5b7f8"
          name="a4c5b7f8"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=59&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="250"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=ae8a8239&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=59&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ae8a8239"
              border="0"
              alt="" /></a
        ></iframe>
        <iframe
          id="ad18c1a1"
          name="ad18c1a1"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=60&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="250"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=a134bcf4&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=60&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a134bcf4"
              border="0"
              alt="" /></a
        ></iframe> -->
      </b-col>

      <!-- Group router view -->
      <b-col md="8" class="col-content">
        <router-view :key="$route.fullPath" />
      </b-col>
      <b-col md="1"></b-col>

     <!--  <b-col md="3">
        <iframe
          id="accebe39"
          name="accebe39"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=53&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="300"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=a28de7f0&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=53&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a28de7f0"
              border="0"
              alt="" /></a
        ></iframe>
        <iframe
          id="a1c5aada"
          name="a1c5aada"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=54&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="300"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=ab8dfbc2&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=54&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ab8dfbc2"
              border="0"
              alt="" /></a
        ></iframe>
        <iframe
          id="aad5c783"
          name="aad5c783"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=55&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="300"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=a9fd4ad0&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=55&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a9fd4ad0"
              border="0"
              alt="" /></a
        ></iframe>
        <iframe
          id="a24d396b"
          name="a24d396b"
          src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=56&amp;cb=INSERT_RANDOM_NUMBER_HERE"
          frameborder="0"
          scrolling="no"
          width="300"
          height="250"
          allow="autoplay"
          ><a
            href="https://ads.clouthub.com/www/delivery/ck.php?n=a1cd22ff&amp;cb=INSERT_RANDOM_NUMBER_HERE"
            target="_blank"
            ><img
              src="https://ads.clouthub.com/www/delivery/avw.php?zoneid=56&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a1cd22ff"
              border="0"
              alt="" /></a
        ></iframe>
      </b-col> -->
    </b-row>

    <!-- Create group modal -->
    <app-group-editor-modal
      :show="showGroupEditor"
      @close="showGroupEditor = false"
    />
  </b-container>
</template>

<style scoped>
@media (min-width: 1400px) {
  .container-small {
    max-width: 820px;
  }
}

.container-small .row {
  --bs-gutter-x: 10px;
}

.container-small .col-content {
  flex: 0 0 auto;
  width: 78%;
}
</style>

<script>
// General components
import AppPageMenu from '@/components/general/PageMenu.vue';
import AppPageMenuItem from '@/components/general/PageMenuItem.vue';

// Group common components
import AppGroupEditorModal from '@/components/groups/GroupEditorModal.vue';

export default {
  components: {
    AppPageMenu,
    AppPageMenuItem,
    AppGroupEditorModal,
  },
  data() {
    return {
      showGroupEditor: false,
    };
  },
  computed: {
    isSmallContainer() {
      return [
        'GroupSearch',
        'RecommendedGroupsList',
        'GroupsForMe',
        'FindGroup',
        'GroupCategory',
        'MyGroupsList',
        'MyGroupsSearch',
      ].includes(this.$route.name);
    },
  },
};
</script>
