<template>
  <div>
    <app-connections-quick-find />
  </div>
</template>

<script>
import AppConnectionsQuickFind from '@/components/general/ConnectionsQuickFind.vue';

export default {
  components: {
    AppConnectionsQuickFind,
  },
};
</script>
