<template>
  <app-modal
    :show="showModal"
    class="ch-post-comment-modal"
    :title="'Post'"
    @close="onClose"
  >
    <div class="ch-post-comment-modal__body">
      <app-post-card :post="post" :isViewComment="true" :autoplay="true" />
    </div>
  </app-modal>
</template>

<script>
import AppModal from "@/components/general/Modal.vue";

export default {
  name: "PostCommentModal",
  components: {
    AppModal,
    AppPostCard: () => import("@/components/post/items/PostCard.vue"),
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onClose() {
      this.$emit("close-modal");
    },
  },
};
</script>


