<template>
  <b-row>
    <app-video-search-bar v-model="query" />
    <b-col :md="isLoggedIn ? 8: 12" class="ch_search_result">
      <app-video-search-results :query="query" :isLoggedIn="isLoggedIn" />
    </b-col>
    <b-col md="4" v-if="isLoggedIn">
      <app-recommended-channel />
    </b-col>
  </b-row>
</template>
<script>
import AppVideoSearchBar from '@/components/video/VideoSearchBar.vue';
import AppVideoSearchResults from '@/components/video/new/VideoSearchResults.vue';
import AppRecommendedChannel from '@/components/video/new/RecommendedChannel.vue';
import $auth from '@/services/auth';
export default {
  components: {
    AppVideoSearchBar,
    AppVideoSearchResults,
    AppRecommendedChannel,
  },
  data() {
    return {
      query: '',
      isLoggedIn: false,
    };
  },
  created() {
    this.updateLoginStatus();
  },
  methods: {
    async updateLoginStatus() {
      this.isLoggedIn = await $auth.isLoggedInAsUser();
    },
  }
};
</script>
<style scoped>
.ch_search_result {
  margin-top: 10px;
  margin-left: -10px;
}
</style>
