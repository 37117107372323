<template>
    <div class="main-verify-email">
        <div v-if="!result">Please Wait While We are verifying your account...</div>
        <div v-if="result">Account Verified!</div>
    </div>
</template>
<script>
import VerifyEmailUsingToken from '@/gql/general/VerifyEmailUsingToken.gql';
export default {
    data() {
        return {
            result: '',
        };
    },
    async mounted() {
        try {
            const resp = await this.$apollo.mutate({
                mutation: VerifyEmailUsingToken,
                variables: {
                    token: this.$route.query.jwtToken
                }
            })
            const result = resp.data.verify_email_using_token;
            if(result) {
                this.result = true;
                const token = this.$route.query.jwtToken;
                localStorage.access_token = token;
                if (localStorage.loginRedirect) {
                    const loggedInUrl = `#${localStorage.loginRedirect}`;
                    window.location.href = loggedInUrl;
                    localStorage.removeItem('loginRedirect');
                    window.location.reload();
                } else {
                    window.location.href = '/';
                }
            }
            else {
                this.result = false;
            }
        }
        catch(err) {
            this.result = false;
        }
    }
}
</script>
<style>
.main-verify-email {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}
</style>