<template>
  <app-modal
    :show="showModal"
    class="ch-post-modal"
    :title="'Post'"
    @close="onClose"
  >
    <div class="ch-post-modal__body">
      <div class="row">
        <div class="col-12 col-lg-7 col-xl-9">
          <div id="media">
            <div v-if="isChanelVideo" class="card-no-padding media-container">
              <app-video
                class="video"
                :src="src"
                :poster="post.video.thumbUrl"
                :vttfile="post.video.vttFile"
                :vttfile_es="post.video.vttFile_es"
                :vttfile_fr="post.video.vttFile_fr"
                :vttfile_de="post.video.vttFile_de"
                :vttfile_zh="post.video.vttFile_zh"
                :vttfile_ar="post.video.vttFile_ar"
                :vttfile_ru="post.video.vttFile_ru"
                :vttfile_vi="post.video.vttFile_vi"
                :vttfile_ko="post.video.vttFile_ko"
                :stream-id="post.video && post.video.streamId"
                :stream-status="post.video && post.video.streamStatus"
                :autoplay="true"
              />
            </div>
            <post-media
              v-else
              :link-preview="targetPost.linkPreview"
              :images="targetPost.images"
              :document="targetPost.document"
              :video="targetPost.video"
              :post-id="targetPost.id"
              :autoplay="true"
              class="media-container"
            />
            <i slot="close-icon" class="cs-icons-close" @click="onClose"></i>
          </div>
        </div>
        <div class="col-lg-5 col-xl-3 info-col" id="info-col">
          <div v-if="isChanelVideo" class="px-4 px-md-0">
            <app-video-detail-header
              v-if="post && post.user"
              :post="post"
              :user="post.user"
              @editVideo="$emit('editVideo')"
              @deleteVideo="$emit('deleteVideo')"
              @downloadVideo="$emit('downloadVideo')"
            />
            <app-video-response
              :post="post"
              :isShowOnModal="true"
              @refresh="$emit('refreshVideo')"
            ></app-video-response>
          </div>
          <div v-else>
            <post-info
              :isNavBarVisible="isNavBarVisible"
              :targetPost="targetPost"
              :preview="preview"
              :canDelete="canDelete"
              :redirectToPostDetailedPage="redirectToPostDetailedPage"
              :isTranslatingPost="isTranslatingPost"
              :showTranslatedPost="showTranslatedPost"
              :isShowingTranslation="isShowingTranslation"
              :isHidingTranslatePost="isHidingTranslatePost"
              :hideTranslatedPost="hideTranslatedPost"
              :displayTranslatedBody="displayTranslatedBody"
              :formatUsername="formatUsername"
              :loadingTranslation="loadingTranslation"
              :getClickableLink="getClickableLink"
              @showTranslatedPost="$emit('showTranslatedPost')"
            />

            <post-response
              v-if="!preview"
              :post="targetPost"
              :isViewComment="true"
            ></post-response>
          </div>
        </div>
      </div>
    </div>
  </app-modal>
</template>

<script>
import AppModal from '@/components/general/Modal.vue';
import PostMedia from '@/components/post/items/PostMedia.vue';
import PostResponse from '@/components/post/response/PostResponse.vue';
import PostInfo from '@/components/post/items/PostInfo.vue';
import $bus from '@/services/bus';
import AppVideo from '@/components/general/Video.vue';
import AppVideoDetailHeader from '@/components/video/VideoDetailHeader.vue';
import AppVideoResponse from '@/components/video/VideoResponse.vue';

export default {
  name: 'PostModal',
  components: {
    AppModal,
    PostMedia,
    PostResponse,
    PostInfo,
    AppVideo,
    AppVideoDetailHeader,
    AppVideoResponse,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: false,
    },
    targetPost: {
      type: Object,
      required: false,
    },
    preview: {
      type: Object,
      required: false,
    },
    isNavBarVisible: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    redirectToPostDetailedPage: {
      type: Function,
    },
    isTranslatingPost: {
      type: Boolean,
      default: false,
    },
    showTranslatedPost: {
      type: Function,
    },
    isShowingTranslation: {
      type: Boolean,
      default: false,
    },
    isHidingTranslatePost: {
      type: Boolean,
      default: false,
    },
    hideTranslatedPost: {
      type: Function,
    },
    displayTranslatedBody: {
      type: Array,
    },
    formatUsername: {
      type: Function,
    },
    loadingTranslation: {
      type: Boolean,
      default: false,
    },

    isChanelVideo: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
    getClickableLink: {
      type: Function,
    },
  },
  mounted() {
    $bus.$on('on-delete-post', () => {
      this.onClose();
    });
  },
  methods: {
    onClose() {
      this.$emit('close-modal');
    },
  },
};
</script>
<style>
.ch-post-modal .ch-modal__popup {
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 0;
  top: 52%;
  max-height: 100%;
}
.ch-post-modal .ch-post-modal__body {
  height: 96vh;
}
.ch-post-modal .ch-modal__body {
  padding: 0;
  overflow: hidden;
}
.ch-post-modal .ch-modal__body .media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  height: 97vh;
  padding: 90px;
}
.ch-post-modal .ch-modal__body .info-col {
  padding-left: 0px !important;
}
.ch-post-modal .media-container .post-image img {
  max-height: 92vh;
}
.ch-post-modal #media {
  position: relative;
}
.ch-post-modal #media .cs-icons-close {
  color: white;
  position: absolute;
  top: 15px;
  left: 10px;
  cursor: pointer;
  font-size: 25px;
}
.ch-post-modal .media-container .video-container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}

.ch-post-modal .ch-modal__header {
  display: none;
}
.ch-post-modal #ch-post-response .ch-post-comments {
  height: 65vh;
  overflow-y: auto;
}
.ch-post-modal #ch-video-response .ch-post-comments {
  height: 45vh;
  overflow-y: auto;
}
.ch-post-modal .video-description {
  overflow-y: auto;
  max-height: 30vh;
}

@media (max-width: 991px) {
  .ch-post-modal .ch-modal__body {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ch-post-modal .media-container {
    height: 50vh !important;
    padding: 40px !important;
  }
  .ch-post-modal .media-container .post-image img {
    max-height: 402px !important;
  }
  .info-col {
    overflow-y: scroll;
    height: 40vh;
    padding-left: 16px;
  }
  .ch-post-modal #ch-post-response .ch-post-comments {
    overflow-y: initial;
  }
  .ch-post-modal #ch-video-response .ch-post-comments {
    overflow-y: initial;
  }
  .ch-post-modal .video-description {
    overflow-y: initial;
    max-height: 100% !important;
  }
}
</style>
