var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meeting-area"},[(_vm.streams.length)?_c('div',{ref:"viewingArea",staticClass:"meeting-stream-area",style:({
      gridTemplateColumns: 'repeat(' + _vm.columnCount + ', minmax(0, 1fr))',
    })},[(_vm.streams.length)?_c('div',{staticClass:"meeting-recording-badge"},[_c('div',{staticClass:"recording-circle"}),_vm._v(" REC ")]):_vm._e(),_c('div',{staticClass:"meeting-full-screen-toggle",on:{"click":function($event){return _vm.toggleFullScreen()}}},[_c('i',{staticClass:"cs-icons-full-screen"})]),_vm._l((_vm.streams),function(stream){return _c('app-meeting-stream',{key:stream.uid,attrs:{"stream":stream}})})],2):_vm._e(),(!_vm.videoStreams.length)?_c('div',{staticClass:"placeholder"},[_vm._v(" Waiting for someone to share video... ")]):_vm._e(),_c('app-meeting-links-bar',{attrs:{"meeting":_vm.meeting}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.role !== 'VIEWER'),expression:"role !== 'VIEWER'"}],staticClass:"meeting-publish-panel"},[_c('div',{staticClass:"cs-textstyle-paragraph meeting-publish-panel__info"},[(_vm.role === 'ADMIN')?_c('div',[(_vm.meeting.liveStreamMode)?_c('div',[_c('app-agora-rtmp-button',{staticClass:"meeting-publish-panel__rtmp-btn",attrs:{"meeting":_vm.meeting}})],1):_c('div',[(_vm.publishedStream)?_c('div',[(_vm.publishedStream)?_c('cs-button',{staticClass:"ch-publish-unpublish-btn offline-button",attrs:{"fill":"outline","size":"medium"},on:{"click":_vm.stopPublishing}},[_vm._v(" GO OFFLINE ")]):_vm._e()],1):(
              !_vm.publishedStream && (_vm.selectedAudioDevice || _vm.selectedVideoDevice)
            )?_c('div',[(!_vm.publishedStream)?_c('cs-button',{staticClass:"ch-publish-unpublish-btn live-button",on:{"click":function($event){return _vm.publish()}}},[_vm._v(" GO LIVE NOW ")]):_vm._e()],1):_c('div',[_vm._v("Please enable your mic and/or camera.")])])]):_vm._e(),(_vm.role === 'MODERATOR')?_c('div',[(_vm.publishedStream)?_c('div',[(_vm.publishedStream)?_c('cs-button',{staticClass:"offline-button",attrs:{"fill":"outline","size":"medium"},on:{"click":_vm.stopPublishing}},[_vm._v(" GO OFFLINE ")]):_vm._e()],1):(
            !_vm.publishedStream && (_vm.selectedAudioDevice || _vm.selectedVideoDevice)
          )?_c('div',[(!_vm.publishedStream)?_c('cs-button',{staticClass:"live-button",on:{"click":function($event){return _vm.publish()}}},[_vm._v(" GO LIVE NOW ")]):_vm._e()],1):_c('div',[_vm._v("Please enable your mic and/or camera.")])]):_vm._e(),(_vm.role === 'PRESENTER')?_c('div',[(_vm.publishedStream)?_c('div',[(_vm.publishedStream)?_c('cs-button',{staticClass:"offline-button",attrs:{"fill":"outline","size":"medium"},on:{"click":_vm.stopPublishing}},[_vm._v(" GO OFFLINE ")]):_vm._e()],1):(
            !_vm.publishedStream && (_vm.selectedAudioDevice || _vm.selectedVideoDevice)
          )?_c('div',[(!_vm.publishedStream)?_c('cs-button',{staticClass:"live-button",on:{"click":function($event){return _vm.publish()}}},[_vm._v(" GO LIVE NOW ")]):_vm._e()],1):_c('div',[_vm._v("Please enable your mic and/or camera to go live.")])]):_vm._e(),(_vm.role === 'PRESENTER_QUEUE')?_c('div',[(_vm.selectedAudioDevice || _vm.selectedVideoDevice)?_c('div',[_vm._v(" Your mic or camera is ready. You'll go live as soon as a host makes it your turn. ")]):_c('div',[_vm._v(" Please enable your mic and camera. You'll go live as soon as a host makes it your turn. ")])]):_vm._e(),(_vm.role === 'SPEAKER')?_c('div',[(_vm.publishedStream)?_c('div',[(_vm.publishedStream)?_c('cs-button',{staticClass:"offline-button",attrs:{"fill":"outline","size":"medium"},on:{"click":_vm.stopPublishing}},[_vm._v(" GO OFFLINE ")]):_vm._e()],1):(_vm.selectedAudioDevice)?_c('div',[(!_vm.publishedStream)?_c('cs-button',{staticClass:"live-button",on:{"click":function($event){return _vm.publish()}}},[_vm._v("GO LIVE NOW")]):_vm._e()],1):_c('div',[_vm._v("Please enable your mic to go live.")])]):_vm._e()]),((!_vm.isAdmin && _vm.meeting.liveStreamMode) || !_vm.meeting.liveStreamMode)?_c('div',{staticClass:"meeting-publish-panel__icons"},[_c('app-stream-control',{ref:"streamControl",attrs:{"can-share-audio":_vm.canShareAudio,"can-share-video":_vm.canShareVideo,"video-stream":_vm.publishedVideoStream,"audio-stream":_vm.publishedAudioStream,"screen-stream":_vm.publishedScreenStream,"role":_vm.role},on:{"audioChange":_vm.handleAudioChange,"videoChange":_vm.handleVideoChange,"screenshare":_vm.shareScreen,"stopscreenshare":_vm.stopScreen}})],1):_vm._e(),(_vm.meeting.status === 'LIVE' && _vm.isAdmin)?_c('app-meeting-end-button',{attrs:{"meeting":_vm.meeting},on:{"end":_vm.endMeeting}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }